"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteVariable = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
const SiteVariableBase = typebox_1.Type.Object({
    /** Identifier for the instance */
    id: typebox_1.Type.String({ format: 'uuid' }),
    /** Name of the site variable, must be unique within the site */
    name: typebox_1.Type.String(),
    /** Descriptive text for the site variable */
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    /** Identifier for the site to which the variable belongs */
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    /** Data to help with presentation in management interface */
    adminUI: model_helpers_1.JsonObject,
    /** Template author specified ordering within a site for sorting */
    sortOrder: typebox_1.Type.Integer(),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Value defines the JSON schema of site_variable data records.
 */
exports.SiteVariable = typebox_1.Type.Union([
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('show_variable'),
        value: typebox_1.Type.Null(),
    }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('text'),
        value: typebox_1.Type.String(),
    }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('asset'),
        value: typebox_1.Type.Object({
            assetId: typebox_1.Type.String({ format: 'uuid' }),
            uri: typebox_1.Type.String({ format: 'uri' }),
        }),
    }),
], { $id: 'SiteVariableModel' });
